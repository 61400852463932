<template>
  <div>
    <ManageSuccessfulAlumni />
  </div>
</template>
<script>
import ManageSuccessfulAlumni from "@/components/ManageSuccessfulAlumni/ManageSuccessfulAlumni";
export default {
  components: {
    ManageSuccessfulAlumni,
  },
  created() {},
};
</script>
