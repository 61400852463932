<template>
  <v-form>
    <v-card width="100%" flat color="#f5f5f5">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" sm="12" style="margin-top: 6px">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            ศิษย์เก่าที่ประสบความสำเร็จ
          </span>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" sm="12">
          <v-card class="pa-6" width="100%" flat>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <span
                  style="
                    width: 415px;
                    height: 69px;

                    font-family: 'IBM Plex Sans Thai';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 23px;
                    text-align: center;
                  "
                >
                  ข้อมูลศิษย์เก่าที่ประสบความสำเร็จ <br />
                  สามารถแก้ไขได้จากระบบฐานข้อมูลศิษย์เก่า บน Airtable
                  ได้ที่ลิ้งค์นี้ <br />
                  <a
                    href="https://airtable.com/shrUah3hGzwx83nWM/tbluqYwPhMUZr92BC"
                    target="_blank"
                    style="color: #2ab3a3"
                    ><u>
                      https://airtable.com/shrUah3hGzwx83nWM/tbluqYwPhMUZr92BC
                    </u>
                  </a>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headersBrandTh: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        {
          text: "ชื่อ",
          value: "name_th",
          align: "start",
        },
        { text: "รุ่น", value: "generation", align: "start" },
        { text: "ระดับการศึกษา", value: "level.name_th", align: "start" },
        { text: "คณะที่เรียน", value: "facualty.name_th", align: "start" },
        { text: "สาขาวิชาที่เรียน", value: "major.name_th", align: "start" },
        { text: "งานที่ทำปัจจุบัน", value: "current_job_th", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    document.title = "ศิษย์เก่าที่ประสบความสำเร็จ - GSCM Management System";
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
      //   val,
      //   auth
      // );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/successfulAlumnis`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/successfulAlumnis/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateSuccessfulAlumni");
    },
    UpdateNews(val) {
      localStorage.setItem("dataSuccessfulAlumni", Encode.encode(val));
      this.$router.push("EditSuccessfulAlumni");
    },
    ViewNews(val) {
      localStorage.setItem("dataSuccessfulAlumni", Encode.encode(val));
      this.$router.push("ViewSuccessfulAlumni");
    },
  },
};
</script>